<template>
  <Link
    :href="href"
    :class="classes"
    class="inline-flex w-full items-center border-b-2 px-1 pt-1 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none"
  >
    <slot />
  </Link>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';

const props = defineProps<{
  href: string;
  active?: boolean;
}>();

const classes = computed(() =>
  props.active
    ? 'border-indigo-400 text-gray-900 focus:border-indigo-700'
    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300',
);
</script>
