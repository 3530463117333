<template>
  <nav class="w-full print:hidden">
    <Menubar :model="items">
      <template #item="{ item }">
        <div class="p-2">
          <NavLink v-ripple :href="item.route" :active="item.active">
            <span v-if="item.icon" :class="item.icon" />
            <span :class="{ 'ml-2': item.icon }">{{ item.label }}</span>
          </NavLink>
        </div>
      </template>
      <template #end>
        <template v-if="isGuest">
          <div class="flex">
            <NavLink :href="route('login')">Einloggen</NavLink>
            <NavLink :href="route('register')">Registrieren</NavLink>
          </div>
        </template>
        <template v-else>
          <button type="button" aria-haspopup="true" aria-controls="overlay_menu" @click="toggle">
            {{ user?.name }}
          </button>
          <Menu id="overlay_menu" ref="menu" :model="dropdownItems" :popup="true">
            <template #item="{ item }">
              <NavLink v-if="item.customMethod === undefined" v-ripple :href="item.route" :active="item.active">
                <span v-if="item.icon" :class="item.icon" />
                <span :class="{ 'ml-2': item.icon }">{{ item.label }}</span>
              </NavLink>
              <NavLink v-else v-ripple :href="item.route" :method="item.customMethod" as="button">
                <span v-if="item.icon" :class="item.icon" />
                <span :class="{ 'ml-2': item.icon }">{{ item.label }}</span>
              </NavLink>
            </template>
          </Menu>
        </template>
      </template>
    </Menubar>
  </nav>
</template>

<script setup lang="ts">
import NavLink from './NavLink.vue';
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import Menubar from 'primevue/menubar';
import { ref } from 'vue';
import type { MenuItem } from 'primevue/menuitem';
import { route } from 'ziggy-js';
import Menu from 'primevue/menu';
import { UserPermission } from '@/types/UserPermissionEnum';

const page = usePage();

const user = computed(() => page.props.auth.user);
const isGuest = computed(() => !user.value);
const canAddResults = computed(() => user.value?.permission_can[UserPermission.ADD_RESULT]);
const canEditPaymentStatus = computed(() => user.value?.permission_can[UserPermission.EDIT_PAYMENT_STATUS]);
const canEditTips = computed(() => user.value?.permission_can[UserPermission.EDIT_TIP]);
const canEditUsers = computed(() => user.value?.permission_can[UserPermission.EDIT_USER]);

const menu = ref();

const items = ref<MenuItem[]>([
  {
    label: 'Startseite',
    route: route('index'),
    active: route().current('index'),
  },
  {
    label: 'Deine Tipps',
    route: route('tipTickets.index'),
    active: route().current('tipTickets.*'),
  },
  {
    label: 'Ergebnisliste',
    route: route('statistic.index'),
    active: route().current('statistic.index'),
  },
  {
    label: 'Spielergebnisse',
    route: route('tipTicketPrintTemplate'),
    active: route().current('tipTicketPrintTemplate'),
  },
  {
    label: 'Spielregeln',
    route: route('rules'),
    active: route().current('rules'),
  },
  // {
  //   label: 'Tippzettel drucken',
  //   route: route('tipTicketPrintTemplate'),
  //   active: route().current('tipTicketPrintTemplate'),
  //   visible: isGuest.value,
  // },
]);

const dropdownItems = ref<MenuItem[]>([
  {
    label: 'Resultate eintragen',
    route: route('admin.results'),
    active: route().current('admin.results'),
    visible: canAddResults.value,
  },
  {
    label: 'Benutzer verwalten',
    route: route('admin.users.index'),
    active: route().current('admin.users.index'),
    visible: canEditUsers.value,
  },
  {
    label: 'Tipps eintragen',
    route: route('admin.tip-tickets.create'),
    active: route().current('admin.tip-tickets.create'),
    visible: canEditTips.value,
  },
  {
    label: 'Bezahlt-Status ändern',
    route: route('admin.tip-tickets.index'),
    active: route().current('admin.tip-tickets.index'),
    visible: canEditPaymentStatus.value,
  },
  {
    label: 'Logout',
    route: route('logout'),
    customMethod: 'POST',
  },
]);

function toggle(event: Event): void {
  menu.value.toggle(event);
}
</script>

<style scoped></style>
