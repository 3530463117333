export enum UserPermission {
  ADD_RESULT = 'add-result',
  EDIT_PAYMENT_STATUS = 'edit-payment-status',

  ADD_USER = 'add-user',
  EDIT_USER = 'edit-user',
  DELETE_USER = 'delete-user',

  EDIT_TIP = 'edit-tip',
  EDIT_TIP_WHEN_PAYED = 'edit-tip-when-paid',
}
